import React from 'react'
import REACTVID from './dragvid.gif'
import styled from 'styled-components';

const InfoRow = styled.img`
    box-sizing: border-box;
    position: absolute;
    width:100%;
    max-height:650px;
`
const Video = () => {
  return (
        <InfoRow src={REACTVID} alt='ff' />
  )
}

export default Video